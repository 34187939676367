<template>
  <search-select
    v-model="selectedItem" class="form-control"
    :placeholder="placeholder"
    :name="name"
    :is-disabled="disabled"
    :options="options"
    @searchchange="onChange" >
  </search-select>
</template>

<script>

export default {
  mounted() {
    if (this.value) {
      this.onLoad( this.value )
    }
    else {
      this.loadOptions( '', () => {
        this._.forEach(this.options, option => {
          if (option.default === true) {
            this.$emit("input", option.value)
          }
        })
      })
    }
  },
  data() {
    return {
      selectedItem: { value: '', text: '' },
      options: [{ text: "직접입력", value: '' }]
    }
  },
  props: {
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    userId: {
      type: [String, Number],
      required: true,
      default: ''
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    }
  },
  watch: {
    value( value, oldValue ) {
      this.onLoad( value )
    },
    selectedItem( item, oldItem ) {
      this.setValue( item.value )
    }
  },
  methods: {
    loadOptions( keyword, callback ) {
      this.$store.dispatch('bankaccount/list', { page: 1, size: 10, filter: 'all', keyword: keyword, user_id: this.userId })
        .then((data) => {
          let { items } = data
          let options = [{ text: "직접입력", value: '' }]
          this._.forEach(items, (item) => {
            let { uid, bank_owner_name, bank_name, is_primary } = item
            options.push({
              text: bank_owner_name + ' / ' + bank_name + ` (${uid})`,
              value: uid,
              default: is_primary
            })
          })
          this.$data.options = options

          if (typeof callback === 'function') {
            callback()
          }
        })
        .catch(e => {
          this.$toast.error(e.message)
        })
    },
    setValue( value ) {
      this.$emit( "input", value)
    },
    onLoad( value ) {
      if (!value) { return }

      this.$emit( "load", {} )

      this.$store.dispatch('bankaccount/get', { uid: value })
          .then((data) => {
            let { uid, bank_owner_name, bank_name } = data

            let selectedItem = {
              text: bank_owner_name + ' / ' + bank_name + ` (${uid})`,
              value: uid
            }

            let hasOption = false
            
            this._.forEach(this.options, option => {
              if (option.value === uid) {
                hasOption = true
              }
            })

            if (!hasOption) {
              this.$data.options = [ { text: "직접입력", value: '' }, selectedItem ]
            }
            
            this.$data.selectedItem = selectedItem
            this.$emit("selected-data", data)
          })
          .catch(e => {
            this.$toast.error(e.message)
          })
    },
    onChange(keyword) {
      if (!keyword) { return }

      this.$emit( "change", { keyword } )

      this.loadOptions( keyword )
    }
  }
}
</script>
